
import $ from 'jquery'


$( document ).ready(function() {

    lazyload();
   $('.js-menu').click(function(e){
       e.preventDefault()
	$('#fullnav').toggleClass('open');
})

   $('.js-top').click(function(ev){
   	ev.preventDefault();
   	console.log("pino culo")
   	$("html, body").animate({ scrollTop: "0px" });
   })
});

$(window).scroll(function(){
	const scrolled = $(window).scrollTop();

	if ( scrolled > 500 ) {
		$('.btt').addClass("open")
	} else {
		$('.btt').removeClass("open")
	}
})